<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
	DropdownMenuContent,
	type DropdownMenuContentEmits,
	type DropdownMenuContentProps,
	DropdownMenuPortal,
	useForwardPropsEmits,
} from 'radix-vue';
import { cn } from '@laam/lib/utils';

const props = withDefaults(
	// eslint-disable-next-line vue/require-default-prop
	defineProps<
		DropdownMenuContentProps & {
			class?: HTMLAttributes['class'];
			variant?: string;
		}
	>(),
	{
		ref: null,
		sideOffset: 4,
		class: '',
		variant: '',
	},
);
const emits = defineEmits<DropdownMenuContentEmits>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<DropdownMenuPortal>
		<DropdownMenuContent
			v-if="variant != null && variant === 'secondary'"
			v-bind="forwarded"
			:ref="props.ref"
			class="dropdown-menu--content"
			:class="
				cn(
					'z-50 min-w-8xl overflow-hidden rounded-small border  shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-md data-[side=left]:slide-in-from-right-md data-[side=right]:slide-in-from-left-md data-[side=top]:slide-in-from-bottom-md bg-white',
					props.class,
				)
			"
		>
			<slot />
		</DropdownMenuContent>
		<DropdownMenuContent
			v-else
			v-bind="forwarded"
			:ref="props.ref"
			:class="
				cn(
					'z-50 min-w-8xl rounded-small border  shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-md data-[side=left]:slide-in-from-right-md data-[side=right]:slide-in-from-left-md data-[side=top]:slide-in-from-bottom-md !overflow-scroll !overflow-y-auto max-h-[500px] mt-[4px]',
					props.class,
				)
			"
		>
			<slot />
		</DropdownMenuContent>
	</DropdownMenuPortal>
</template>
