<script setup lang="ts">
import {
	DropdownMenuTrigger,
	type DropdownMenuTriggerProps,
	useForwardProps,
} from 'radix-vue';

const props = defineProps<DropdownMenuTriggerProps>();

const forwardedProps = useForwardProps(props);
</script>

<template>
	<DropdownMenuTrigger
		class="dropdown-menu--trigger outline-none"
		v-bind="forwardedProps"
	>
		<slot />
	</DropdownMenuTrigger>
</template>
